$primary: #333333;
$black: #000000;
$gray-1: #333333;
$gray-2: #4f4f4f;
$gray-3: #828282;
$gray-4: #bdbdbd;
$gray-5: #ebebeb;
$gray-6: #f2f2f2;
$white: #ffffff;
$blue: #00a0fb;

.App {
  text-align: left;
  font-size: 14px;
}

:focus {
  outline: none !important;
}

@media screen and (max-width: 1920px) {
  .mobile {
    display: none;
  }
}
@media screen and (max-width: 906px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

.Responsive-width {
  @media (min-width: 1200px) {
    width: 1000px;
  }

  @media (max-width: 1200px) {
    width: 85%;
  }
}

a {
  text-decoration: none;
  color: $blue;
  font-weight: 400;
}

p {
}

ul {
  padding-left: 8px;
  list-style: square inside;
  list-style-position: inside;
}

li {
  margin: 0px 0px 0px 0px;
  padding: 4px 0px 0px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

hr {
  border: 0;
  height: 1px;
  background-color: $gray-5;
  margin: 16px 0px 16px 0px;
}

.Navbar {
  margin: auto;
  // width: 60%;
  padding: 80px 0px 32px 0px;
}

.Footer {
  margin: auto;
  // width: 60%;
  font-size: 14px;
  padding: 16px 0px 0px 0px;
  color: $gray-3;
}

.Finder-container {
  margin: auto;
  height: 480px;
  background-color: $white;
  border-radius: 6px;
}

.Finder-container:after {
  content: "";
  display: table;
  clear: both;
}

.Finder-column-lists {
  float: left;
  overflow: scroll;
  width: 31%;
  padding: 32px 12px 32px 12px;
  height: 100%;
  border-right: 1px solid $gray-5;
}

.Finder-column-details {
  float: left;
  width: 38%;
  padding: 32px 12px 32px 12px;
  height: 100%;
  overflow: scroll;
  border-right: 1px solid $gray-5;
}

.Finder-cell {
  padding: 12px;
  border-bottom: 1px solid $gray-5;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  // border-radius: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Finder-cell-selected {
  padding: 12px;
  font-weight: 500;
  color: $white;
  background-color: $gray-4;
  border-radius: 4px;
  border-bottom: 1px solid $gray-5;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Finder-cell-current {
  padding: 12px;
  font-weight: 500;
  color: $white;
  background-color: $blue;
  border-radius: 4px;
  border-bottom: 1px solid $gray-5;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Finder-cell-inactive {
  padding: 12px;
  font-weight: 500;
  background-color: $gray-4;
  color: $gray-5;
  border-radius: 4px;
  border-bottom: 1px solid $gray-5;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.red {
  color: red;
}

.icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-bottom: 3px;
  vertical-align: middle;
  background-size: auto 100%;
  margin-right: 8px;
}

.Detail-image {
  background-color: $gray-1;
  border-radius: 4px;
  width: 100%;
  height: 224px;
}

.Detail-image img {
  width: 100%;
  border-radius: 4px;
}

.Detail-header {
  font-size: 20px;
  font-weight: 700;
  margin: 12px 0px 8px 0px;
}

.Detail-button {
  width: 90%;
  height: 32px;
  background-color: $gray-2;
  border-radius: 4px;
  border: 0;
  font-size: 14px;
  color: $white;
  margin: 8px 0px 8px 0px;
}

.Detail-body {
  font-size: 14px;
  color: $gray-2;
  line-height: 150%;
  margin: 8px 0px 24px 0px;
}

.Detail-metadata {
  font-size: 14px;
  margin: 0px 0px 24px 0px;
  color: gray;
}

.Detail-subheader {
  font-size: 16px;
  font-weight: 700;
  margin: 16px 0px 16px 0px;
}

.Detail-link {
  font-size: 14px;
  margin: 0px 0px 0px 0px;
}

* {
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.tooltip {
  background-color: $gray-6;
  border-radius: 4px;
  padding: 16px;
  margin: 0px;
}

.tooltip-span {
  background-color: $white;
  border-radius: 4px;
  padding: 6px;
  margin: 0px 4px 0px 8px;
}

.tooltip-body {
  font-size: 14px;
  color: $gray-2;
  line-height: 2.2;
  margin: 8px 0px 0px 0px;
}

.banner {
  background-color: $gray-5;
  color: $gray-3;
  font-size: 14px;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 32px;
}

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
